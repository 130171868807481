// title: Articles
// frameCardName: Doran, the Siege Tower
// frameCardSet: LRW

import * as styles from './articles.module.scss'

import React from 'react'
import { Link, graphql, PageProps } from 'gatsby'
import { compact } from 'lodash'

import Layout from 'components/layout/Layout'
import PageMetaData from 'src/components/layout/PageMetaData'
import * as Typography from 'components/typography'

interface Props extends PageProps<Queries.ArticlesPageQuery> {
  pageContext: any
}

const ArticlesPage: React.FC<Props> = (props) => {
  const { articles } = props.data.allMdx

  return (
    <Layout
      {...props.pageContext}
      accentColor="#9C4425"
      includeNewsletterSignup
    >
      <div className={styles.container}>
        {articles.map((article) => {
          if (article.frontmatter == null) {
            return
          }

          return (
            <Link key={article.id} to={article.fields?.slug || ''}>
              <article className={styles.article}>
                <h3>
                  <Typography.TertiaryHeading>
                    {article.frontmatter.series}
                  </Typography.TertiaryHeading>
                </h3>
                <h2 className={styles.articleTitle}>
                  <Typography.PrimaryHeading>
                    {article.frontmatter.title}
                  </Typography.PrimaryHeading>
                </h2>
                <div className={styles.articleMeta}>
                  {article.frontmatter.date}
                  {article.frontmatter.authors != null && (
                    <>
                      {' '}
                      —{' '}
                      {compact(article.frontmatter.authors)
                        .map((author) => author.name)
                        .join(' & ')}
                    </>
                  )}
                </div>
                <Typography.Paragraph>
                  {article.frontmatter.excerpt}
                </Typography.Paragraph>
              </article>
            </Link>
          )
        })}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ArticlesPage {
    allMdx(
      filter: { fields: { type: { eq: "articles" } } }
      sort: { frontmatter: { date: DESC } }
    ) {
      articles: nodes {
        id
        frontmatter {
          title
          series
          date(formatString: "MMMM Do, YYYY")
          excerpt
          authors {
            name
          }
        }
        fields {
          slug
        }
      }
    }
  }
`

export const Head: React.FC<Props> = (props) => {
  return (
    <PageMetaData
      url={props.pageContext.url}
      metaData={{
        title: 'Articles — Lucky Paper',
        description:
          'Articles about Magic: the Gathering focused on Cube and other sandbox environments and data analysis.',
      }}
    />
  )
}

export default ArticlesPage
